import EthProvider from '@walletconnect/ethereum-provider'

import { BaseConnector } from './base-connector'
import { WC_OPTIONAL_CHAIN_IDS, WC_RPC_MAP, WC_SUPPORTED_CHAIN_IDS, WC_SUPPORTED_METHODS } from './constants'
import {
  ConnectorType,
  EIP1193Event,
  EIP1193Provider,
  InitialData,
  IRoninMobileConnector,
  MobileConnectorOptions,
  WCEvent,
} from './types'
import { openMobileWallet, toCaipChain, toDeepLink } from './utils'

const GET_INITIAL_DATA_TIMEOUT = 3000

export class RoninMobileConnector extends BaseConnector implements IRoninMobileConnector {
  ethProvider?: EthProvider
  autoHandleDisplayUri?: boolean

  constructor() {
    super()
    this.type = ConnectorType.MOBILE
  }

  setupProviderEventListeners() {
    if (!this.ethProvider) {
      throw new Error('WalletConnect is not initialized')
    }

    this.ethProvider.on(WCEvent.DISPLAY_URI, async (uri: string) => {
      if (this.autoHandleDisplayUri) {
        openMobileWallet(toDeepLink(uri))
      }
      this.emit(WCEvent.DISPLAY_URI, uri)
    })

    this.ethProvider.on(
      WCEvent.SESSION_EVENT,
      ({
        params: {
          event: { name, data },
        },
      }: {
        params: { event: { name: string; data: unknown } }
      }) => {
        switch (name) {
          case EIP1193Event.ACCOUNTS_CHANGED:
            this.emit(EIP1193Event.ACCOUNTS_CHANGED, data)

            return

          case EIP1193Event.CHAIN_CHANGED:
            this.provider = this.ethProvider as unknown as EIP1193Provider
            this.emit(EIP1193Event.CHAIN_CHANGED, data)

            return

          default:
            return
        }
      }
    )
  }

  async init(options: MobileConnectorOptions) {
    const { projectId, clientMeta, autoHandleDisplayUri = false } = options
    this.ethProvider = await EthProvider.init({
      projectId: projectId as string,
      chains: WC_SUPPORTED_CHAIN_IDS,
      metadata: clientMeta,
      showQrModal: false,
      rpcMap: WC_RPC_MAP,
      methods: WC_SUPPORTED_METHODS,
      optionalChains: WC_OPTIONAL_CHAIN_IDS,
      disableProviderPing: true,
    })
    this.autoHandleDisplayUri = autoHandleDisplayUri
    this.setupProviderEventListeners()
  }

  private async getInitialDataOrDefault(): Promise<InitialData> {
    const defaultAccounts = await this.syncAccount()
    const defaultChainId = await this.syncChainId()

    const defaultDataPromise: Promise<InitialData> = new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          resolve({
            account: defaultAccounts.length ? defaultAccounts[0] : undefined,
            chainId: defaultChainId as number,
          })
        } catch (e) {
          reject(e)
        }
      }, GET_INITIAL_DATA_TIMEOUT)
    })

    return Promise.race([defaultDataPromise, this.getInitialData()])
  }

  async connect() {
    if (!this.ethProvider) {
      throw new Error('WalletConnect is not initialized')
    }
    this.provider = this.ethProvider as unknown as EIP1193Provider
    await this.ethProvider.enable()
    const initialData = await this.getInitialDataOrDefault()
    this.emit(EIP1193Event.CONNECT, initialData)

    return initialData
  }

  private reset() {
    this.ethProvider = undefined
    this.provider = undefined
    this.removeAllListeners()
  }

  async disconnect() {
    if (!this.ethProvider) {
      return
    }
    await this.ethProvider?.disconnect()
    await this.reset()
  }

  setChainId(chainId: number) {
    this.emit(EIP1193Event.CHAIN_CHANGED, chainId)
    this.ethProvider?.signer.setDefaultChain(toCaipChain(chainId))
  }
}
