import { CopyIcon, RonIcon } from '@axieinfinity/dango-icons'
import { ASProfile } from '@axieinfinity/hub-services'
import { toast } from 'sonner'

import { Tooltip } from '#/components/common/tooltip'
import { BirdIcon } from '#/constants'
import { useCaptureEvent, useHandler } from '#/hooks'
import { truncateAddress } from '#/utils'

import styles from './address-info.module.scss'

type Props = Pick<ASProfile, 'accountWallet'> & {
  className?: string
}

export const AddressInfo: React.FC<Props> = ({
  className,
  accountWallet,
}) => {
  const captureEvent = useCaptureEvent()
  const { handleSetClipboard } = useHandler()

  function handleCopyAddressToClipboard(text: string) {
    return () => {
      captureEvent('Click address to Copy')
      handleSetClipboard(text)?.then(() =>
        toast.message('Copied to clipboard!')
      )
    }
  }

  const {
    identity: idAddress,
    secondary: secondaryAddress,
  } = accountWallet || {}

  if (!idAddress && !secondaryAddress) {
    return null
  }

  const renderItem = (displayName: string, address: string, icon: React.ReactNode) => (
    <div className={styles.item}>
      <div className={styles.displayName}>
        {icon}
        <span>{displayName}</span>
      </div>
      <Tooltip side="bottom" content="Click to copy">
        <div className={styles.address}>
          <div className={styles.addressText}>{truncateAddress(address)}</div>
          <CopyIcon size={20} onClick={handleCopyAddressToClipboard(address)} />
        </div>
      </Tooltip>
    </div>
  )

  return (
    <div className={className}>
      <div className={styles.container}>
        {idAddress && renderItem('Keyless Wallet', idAddress, <BirdIcon size={20} color="#2065EE" />)}
        {secondaryAddress && renderItem('Ronin Wallet', secondaryAddress, <RonIcon size={20} color="#004DE5" />)}
      </div>
    </div>
  )
}
